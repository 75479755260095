import React from "react";
import { useEffect } from "react";
import $ from "jquery";

export default function MoveTable() {
  useEffect(() => {
    var $table = $(".control-flow");
    $(".move").click(function () {
      var $target = $table.find("td.target");
      if ($target.length == 0) $target = $table.find("td:first");

      $target = $target[$(this).data("dir")]("td");
      if ($target.length == 0) return;

      $(".control-flow")
        .stop()
        .animate({ scrollLeft: $target.position().left });
      $table.find(".target").removeClass("target");
      $target.addClass("target");
    });
  }, []);
  return (
    <div className="left-rig-arrowtable">
      <button className="move" data-dir="prev">
        <img src="/images/left.png" alt="..." />
      </button>
      <button className="move" data-dir="next">
        <img src="/images/right.png" alt="..." />
      </button>
    </div>
  );
}
