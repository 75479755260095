import axios from "axios";

const API_URL = process.env.REACT_APP_API_Link;

const getRsaList = (store, url, empId) => {
  console.log(url);
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(`${API_URL}/rsa/list/${store}/${url}?employee_id=${empId}`, {
    headers: headers,
  });
};

const getRsaListByWebsite = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/rsa/list/${values.website}?employee_id=${values.search}`,
    {
      headers: headers,
    }
  );
};

const getRsaById = (id, url) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(`${API_URL}/rsa/get-by-id/${id}/${url}`, {
    headers: headers,
  });
};

// FIXME store_location, store_name city site
const addRsa = (values, url) => {
  const headers = {
    "Content-Type": "application/json",
  };
  if (url == "cityfurnituretestrest.com" || url == "ashleysfltestrest.com") {
    return axios.post(
      `${API_URL}/rsa/add/${url}`,
      {
        email: values.email,
        fname: values.fname,
        lname: values.lname,
        employee_id: values.employee_id,
        store_name: values.store_location,
      },
      {
        headers: headers,
      }
    );
  } else {
    return axios.post(
      `${API_URL}/rsa/add/${url}`,
      {
        email: values.email,
        fname: values.fname,
        lname: values.lname,
        employee_id: values.employee_id,
        store_location: values.store_location,
      },
      {
        headers: headers,
      }
    );
  }
};

// FIXME store_location, store_name city site
const updateRsa = (id, values, url) => {
  console.log(`id=${id}, url=${url}`);
  console.log(values);
  const headers = {
    "Content-Type": "application/json",
  };
  if (url == "cityfurnituretestrest.com" || url == "ashleysfltestrest.com") {
    return axios.put(
      `${API_URL}/rsa/update/${id}/${url}`,
      {
        email: values.email,
        fname: values.fname,
        lname: values.lname,
        employee_id: values.employee_id,
        store_name: values.store_location,
      },
      {
        headers: headers,
      }
    );
  } else {
    return axios.put(
      `${API_URL}/rsa/update/${id}/${url}`,
      {
        email: values.email,
        fname: values.fname,
        lname: values.lname,
        employee_id: values.employee_id,
        store_location: values.store_location,
      },
      {
        headers: headers,
      }
    );
  }
};

const deleteRsa = (id, url) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.delete(`${API_URL}/rsa/delete/${id}/${url}`, {
    headers: headers,
  });
};

const getWebsiteReports = (values, limit, page) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/register/list/${values.website}?startDate=${values.start}&endDate=${values.end}&limit=${limit}&page=${page}`,
    {
      headers: headers,
    }
  );
};

const getDataForExport = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/register/download-register-by-range/${values.website}/${values.start}/${values.end}`,
    {
      headers: headers,
    }
  );
};

const getRsaDataForExport = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/register/download-rsa-by-range/${values.website}/${values.start}/${values.end}`,
    {
      headers: headers,
    }
  );
};

const getWebsiteRegistrations = (website, limit, page) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/register/view-register-data/${website}?limit=${limit}&page=${page}`,
    {
      headers: headers,
    }
  );
};

const getWebsiteRegistrationsFiltered = (website, values, limit, page) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/register/custom-register-search/${website}?limit=${limit}&page=${page}${
      values?.fieldtype !== "date"
        ? "&" + values.fieldtype + "=" + values.searchval
        : "&start_date=" + values.start + "&end_date=" + values.end
    }`,
    {
      headers: headers,
    }
  );
};

const getRegisteredDataForExport = (values, website) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/register/export-search-register/${website}${
      values?.fieldtype &&
      values?.fieldtype === "date" &&
      values.start &&
      values.end
        ? "?start_date=" + values.start + "&end_date=" + values.end
        : values?.fieldtype && values.searchval
        ? "?" + values.fieldtype + "=" + values.searchval
        : ""
    }`,
    {
      headers: headers,
    }
  );
};

const deleteRegisteredUser = (id, url) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.delete(
    `${API_URL}/register/delete-register-by-id/${url}/${id}`,
    {
      headers: headers,
    }
  );
};

const RsaService = {
  getRsaList,
  getRsaListByWebsite,
  getRsaById,
  addRsa,
  deleteRsa,
  updateRsa,
  getWebsiteReports,
  getDataForExport,
  getRsaDataForExport,
  getWebsiteRegistrations,
  getWebsiteRegistrationsFiltered,
  getRegisteredDataForExport,
  deleteRegisteredUser,
};

export default RsaService;
