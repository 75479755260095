import React, { useLayoutEffect, useRef } from "react";
import $ from "jquery";

const Select2Field = ({ name, label, options, webChange }) => {
  const selectRef = useRef();

  useLayoutEffect(() => {
    const $select = $(selectRef.current);

    $select.select2();

    $select.on("change", (event) => {
      webChange(event);
    });

    return () => {
      $select.select2("destroy");
      $select.off("change");
    };
  }, []);

  return (
    <>
      <select
        ref={selectRef}
        name={name}
        className="form-select register-select2"
        id={name}
      >
        <option value="">Select {label}</option>
        {options.map((opt) =>
          opt.name !== "id" ? (
            <option key={opt.url} value={opt.url}>
              {opt.name}
            </option>
          ) : null
        )}
      </select>
    </>
  );
};

export default Select2Field;
